import React, { FC } from 'react';
import { Link } from 'gatsby';
import UmbracoImage from 'common/Image/UmbracoImage';
import { LandingPageBlockProps } from './models';

const Benefits: FC<LandingPageBlockProps> = ({ component }) => (
  <div className="landing-page__benefits-container">
    <div className="landing-page__benefits">
      <UmbracoImage
        className="landing-page__benefits-packshot"
        image={component?.image}
        alt={component?.structure}
      />
      {component.benefitBlocks.map((benefitBlock) => (
        <div className="landing-page__benefits-block" key={benefitBlock.title}>
          <h3>
            <span>{benefitBlock.title}</span>
            <strong>{benefitBlock.subTitle}</strong>
          </h3>
          <div className="landing-page__benefits-list">
            {benefitBlock.benefitsList.map((benefit) => (
              <div className="landing-page__benefit" key={benefit.text}>
                <UmbracoImage
                  className="landing-page__benefit-icon"
                  image={benefit.image}
                  alt={benefit.text}
                />
                <div
                  className="landing-page__benefit-text"
                  dangerouslySetInnerHTML={{ __html: benefit.text }}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
    <Link to={component.landingPageCta.url} className="landing-page__cta">
      {component.landingPageCta.name}
    </Link>
  </div>
);

export default Benefits;
