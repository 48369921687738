import React, { FC } from 'react';
import Slider from 'react-slick';
import { useScreenRecognition } from 'utils/hooks';
import { LandingPageBlockProps } from './models';

const Reviews: FC<LandingPageBlockProps> = ({ component }) => (
  <Slider
    slidesToShow={useScreenRecognition().isXl ? 3 : 1}
    arrows={false}
    infinite={false}
    className="landing-page__reviews"
  >
    {component.reviews.map((review, index) => (
      <div className="landing-page__review" key={review.name}>
        <div className={`landing-page__review-inner ${index % 2 ? 'odd' : ''}`}>
          <div className="landing-page__review-content">
            <img
              src="/images/5stars.webp"
              alt={`${review.stars} stars`}
              className="landing-page__review-stars"
            />
            <div className="landing-page__review-name">{review.name}</div>
            <div className="landing-page__review-title">{review.title}</div>
            <div className="landing-page__review-text">{review.text}</div>
          </div>
          <div className="landing-page__review-verdict">
            <img
              className="landing-page__review-verdict-icon"
              src="/images/tick.webp"
              alt={`${review.stars} stars`}
            />
            <div className="landing-page__review-verdict-text">{review.verdict}</div>
          </div>
        </div>
      </div>
    ))}
  </Slider>
);

export default Reviews;
