import React, { FC } from 'react';
import { Link } from 'gatsby';
import UmbracoImage from 'common/Image/UmbracoImage';
import Equalizer from 'react-equalizer';
import { LandingPageBlockProps } from './models';

const Results: FC<LandingPageBlockProps> = ({ component }) => (
  <div className="landing-page__results">
    <div className="landing-page__results-left">
      <div className="landing-page__results-stats">
        {component.results.map((result) => (
          <div className="landing-page__result-stat" key={result.text}>
            <div
              className="landing-page__result-text"
              dangerouslySetInnerHTML={{ __html: result.text }}
            />
            <UmbracoImage
              image={result.image}
              alt={result.text}
              className="landing-page__result-image"
            />
          </div>
        ))}
      </div>
      <UmbracoImage
        image={component.image}
        alt={component.resultsDisclaimer}
        className="landing-page__legend-image"
      />
      <div
        className="landing-page__disclaimer"
        dangerouslySetInnerHTML={{ __html: component.resultsDisclaimer }}
      />
      <Link className="landing-page__cta" to={component.landingPageCta.url}>
        {component.landingPageCta.name}
      </Link>
    </div>
    <Equalizer byRow={false} className="landing-page__instructions">
      {component.instructions.map((instruction) => (
        <div className="landing-page__instruction" key={instruction.text}>
          <UmbracoImage
            image={instruction.image}
            alt={instruction.text}
            className="landing-page__instruction-image"
          />
          <div
            className="landing-page__instruction-text"
            dangerouslySetInnerHTML={{ __html: instruction.text }}
          />
        </div>
      ))}
    </Equalizer>
  </div>
);

export default Results;
