import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useScreenRecognition } from 'utils/hooks';
import ScreenRecognitionContext from 'utils/context/screenRecognitionContext';
import { LayoutProps } from '../Layout/models';

const LandingPageLayout: FC<LayoutProps> = ({ children, className }) => {
  const CSSclassNames = classNames('layout', className);

  const screenResolution = useScreenRecognition();
  const screenResolutionProviderValues = useMemo(() => ({ screenResolution }), [screenResolution]);

  return (
    <ScreenRecognitionContext.Provider value={screenResolutionProviderValues.screenResolution}>
      <div className={CSSclassNames}>
        <main id="main" className="layout__main">
          {children}
        </main>
      </div>
    </ScreenRecognitionContext.Provider>
  );
};

export default LandingPageLayout;
