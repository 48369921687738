import React, { FC } from 'react';
import UmbracoImage from 'common/Image/UmbracoImage';
import { LandingPageBlockProps } from './models';

const More: FC<LandingPageBlockProps> = ({ component }) => (
  <div className="landing-page__more">
    {component.more.map((item) => (
      <div className="landing-page__more-item" key={item.text}>
        <UmbracoImage image={item.image} alt={item.text} className="landing-page__more-image" />
        <div className="landing-page__more-text" dangerouslySetInnerHTML={{ __html: item.text }} />
      </div>
    ))}
  </div>
);

export default More;
