import React, { FC } from 'react';
import { useScreenRecognition } from 'utils/hooks';
import { LandingPageBlockProps } from './models';

const Video: FC<LandingPageBlockProps> = ({ component }) => (
  <iframe
    className="landing-page__video-iframe"
    width={useScreenRecognition().isXl ? 760 : 330}
    height={useScreenRecognition().isXl ? 427 : 186}
    src={`https://www.youtube.com/embed/${component.videoId}`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title={component.structure}
  />
);

export default Video;
