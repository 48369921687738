import React, { FC } from 'react';
import { graphql } from 'gatsby';
import UmbracoImage from 'common/Image/UmbracoImage';
import LandingPageLayout from 'layout/LandingPageLayout';
import LandingPageHeader from 'templates/LandingPage/LandingPageHeader';
import Footer from 'components/Footer';
import slugify from 'react-slugify';
import { useScreenRecognition } from 'utils/hooks';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import Benefits from './Benefits';
import Results from './Results';
import More from './More';
import Reviews from './Reviews';
import Video from './Video';
import { LandingPageProps } from './models';
import './LandingPage.scss';

const LandingPage: FC<LandingPageProps> = ({
  data: {
    allUmbracoLandingPage: {
      nodes,
      nodes: [{ langProps, landingPageBlocks, banners, navigationCtaLink }],
    },
    allBrandSettings,
    socialMedia,
  },
}) => {
  const {
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, ogImage },
    langProps: { lang },
  } = nodes[0];

  return (
    <LandingPageLayout langProps={langProps} className="landing-page">
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          ogImage,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <LandingPageHeader
        brandSettings={allBrandSettings.nodes[0]}
        links={landingPageBlocks.map((block) => {
          return {
            url: `#${slugify(block.menuHeader)}`,
            name: block.menuHeader,
          };
        })}
        ctaLink={navigationCtaLink}
      />
      <div className="landing-page__banner">
        {useScreenRecognition().isXS ? (
          <UmbracoImage
            image={banners.mobileBannerImage}
            className="landing-page__mobile-banner"
            alt={banners.imageAlt}
          />
        ) : (
          <UmbracoImage
            image={banners.desktopBannerImage}
            className="landing-page__desktop-banner"
            alt={banners.imageAlt}
          />
        )}
      </div>
      {landingPageBlocks.map((block) => (
        <div className="landing-page__block" id={slugify(block.menuHeader)}>
          <div className="landing-page__container">
            <h2>{block.header}</h2>
            {{
              Benefits: (b) => <Benefits {...b} key={block.component.structure} />,
              Results: (b) => <Results {...b} />,
              More: (b) => <More {...b} />,
              Reviews: (b) => <Reviews {...b} />,
              Video: (b) => <Video {...b} />,
            }[block.component.structure]?.(block)}
          </div>
        </div>
      ))}
      <Footer
        langProps={langProps}
        isCustomLogo={false}
        brandSettings={allBrandSettings.nodes[0]}
        socialMedia={socialMedia}
      />
    </LandingPageLayout>
  );
};

export const query = graphql`
  query LandingPageQuery($pageId: String) {
    allUmbracoLandingPage(filter: { id: { eq: $pageId } }) {
      nodes {
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
          ogImage
        }
        banners {
          desktopBannerImage {
            ...umbracoImage
          }
          mobileBannerImage {
            ...umbracoImage
          }
        }
        navigationCtaLink {
          url
          name
        }
        landingPageBlocks {
          header
          menuHeader
          component {
            structure
            image {
              ...umbracoImage
            }
            reviews {
              name
              stars
              title
              text
              verdict
            }
            results {
              image {
                ...umbracoImage
              }
              text
            }
            instructions {
              image {
                ...umbracoImage
              }
              text
            }
            more {
              image {
                ...umbracoImage
              }
              text
            }
            benefitBlocks {
              title
              subTitle
              benefitsList {
                image {
                  ...umbracoImage
                }
                text
              }
            }
            landingPageCta {
              url
              name
            }
            resultsDisclaimer
            videoId
          }
        }
      }
    }
    allBrandSettings {
      nodes {
        brandName
        url
        lang
        brandLogo {
          ...umbracoImage
        }
        customLogoRegex
        customBrandLogo {
          ...umbracoImage
        }
      }
    }
    socialMedia {
      socialMediaItems {
        properties {
          socialMediaIcon
          socialMediaAriaLabel
          isVisible
          socialMediaIconColor {
            label
            value
          }
          socialMediaHoverIconColor {
            label
            value
          }
          socialMediaLink {
            name
            target
            url
            icon
            published
          }
        }
      }
    }
  }
`;

export default LandingPage;
