import React, { FC } from 'react';
import { Link } from 'gatsby';
import Logo from 'common/Logo';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { LandingPageHeaderProps } from './models';

const LandingPageHeader: FC<LandingPageHeaderProps> = ({
  brandSettings: { brandLogo, customBrandLogo, brandName, url },
  links,
  ctaLink,
}) => (
  <div className="landing-page__header landing-page__container">
    <Logo {...{ brandLogo, customBrandLogo, brandName, homepageLink: url, isCustomLogo: false }} />
    <Link className="landing-page__header-cta-link" to={ctaLink.url}>
      {ctaLink.name}
    </Link>
    {
      // check if array has items
      links.length > 0 && (
        <div className="landing-page__nav">
          {links.map(
            (link, index) =>
              // check if the menu item should be rendered as a link
              link.name && (
                <div
                  tabIndex={index}
                  role="link"
                  onClick={() => scrollTo(link.url)}
                  className="landing-page__nav-item"
                  key={link.name}
                >
                  {link.name}
                </div>
              )
          )}
        </div>
      )
    }
  </div>
);

export default LandingPageHeader;
